var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vx-card", { staticClass: "border-grey border-solid border p-0" }, [
    _c("div", { staticClass: "grid grid-cols-12 gap-4" }, [
      _c(
        "div",
        { staticClass: "col-span-12" },
        [
          _c("vx-card", { staticClass: "mx-0 px-0 shadow-none" }, [
            _c("div", { staticClass: "grid gap-2" }, [
              _vm.questionnaireName
                ? _c(
                    "div",
                    { staticClass: "flex flex-col row-start-1 col-span-3" },
                    [
                      _c("label", { staticClass: "font-bold" }, [
                        _vm._v(_vm._s(_vm.$t("Questionnaire"))),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.questionnaireName))]),
                    ]
                  )
                : _vm._e(),
              _vm.questionnaireOwner
                ? _c("div", { staticClass: "flex flex-col row-start-2" }, [
                    _c("label", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(_vm.$t("owner_by"))),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.questionnaireOwner))]),
                  ])
                : _vm._e(),
              _vm.questionnaireCreatedAt
                ? _c("div", { staticClass: "flex flex-col row-start-2" }, [
                    _c("label", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(_vm.$t("criado-em"))),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.questionnaireCreatedAt))]),
                  ])
                : _vm._e(),
              _vm.questionnairePublishedAt
                ? _c("div", { staticClass: "flex flex-col row-start-2" }, [
                    _c("label", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(_vm.$t("Release_Date"))),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.questionnairePublishedAt))]),
                  ])
                : _vm._e(),
              _vm.questionnaireTotalScore
                ? _c("div", { staticClass: "flex flex-col row-start-2" }, [
                    _c("label", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(_vm.$t("pontuacao-total"))),
                    ]),
                    _c("span", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.questionnaireTotalScore)),
                    ]),
                  ])
                : _vm._e(),
              _vm.questionnaireStartAt || _vm.questionnaireFinishAt
                ? _c(
                    "div",
                    { staticClass: "flex flex-col col-span-4 row-start-3" },
                    [
                      _c("label", { staticClass: "font-bold" }, [
                        _vm._v(_vm._s(_vm.$t("fields.term"))),
                      ]),
                      _vm.questionnaireStartAt !== null ||
                      _vm.questionnaireFinishAt !== null
                        ? _c("span", { staticClass: "flex items-center" }, [
                            _c("span", [
                              _vm.questionnaireStartAt !== null
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("inicio-as")) +
                                        " " +
                                        _vm._s(_vm.questionnaireStartAt) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.questionnaireFinishAt !== null
                                ? _c("span", [
                                    _vm.questionnaireStartAt !== null
                                      ? _c("span", [_vm._v(" - ")])
                                      : _vm._e(),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("termino-as")) +
                                        " " +
                                        _vm._s(_vm.questionnaireFinishAt) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm.description
                ? _c(
                    "div",
                    { staticClass: "flex flex-col col-span-4 row-start-4" },
                    [
                      _c("label", { staticClass: "font-bold" }, [
                        _vm._v(_vm._s(_vm.$t("informacoes-gerais"))),
                      ]),
                      _vm.description
                        ? _c("div", {
                            directives: [
                              {
                                name: "html-safe",
                                rawName: "v-html-safe",
                                value: _vm.description,
                                expression: "description",
                              },
                            ],
                            staticClass: "editor-content ck-content",
                            attrs: { id: "descriptionElement" },
                          })
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "col-span-12" }, [
        _c(
          "div",
          [
            _vm._l(_vm.answeredQuestionnaireFields, function (field, index) {
              return _c(
                "div",
                { key: field.id, staticClass: "px-4" },
                [
                  _c("vx-card", { class: _vm.getRowClass(field) }, [
                    _vm._.get(field, "type", null) !== "section"
                      ? _c("div", { staticClass: "grid grid-rows-1" }, [
                          _c("div", { staticClass: "grid grid-cols-2" }, [
                            _c("div", { staticClass: "float-left" }, [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12px" } },
                                [_vm._v(_vm._s(_vm.scoreSet(field)))]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        field.type === "section"
                          ? _c("SectionAnswer", {
                              attrs: {
                                item: { data: { title: field.data.title } },
                              },
                            })
                          : _vm._e(),
                        field.type === "simple_text"
                          ? _c("SimpleTextShow", {
                              attrs: {
                                item: field,
                                showAnswerSection: false,
                                hide_score: false,
                                showQuestionNumber: true,
                                showQuestionScore: true,
                                index: _vm.questionNumber(index),
                              },
                            })
                          : _vm._e(),
                        field.type === "multiple_choice"
                          ? _c("MultipleChoiceShow", {
                              attrs: {
                                item: field,
                                showQuestionNumber: true,
                                showQuestionScore: true,
                                index: _vm.questionNumber(index),
                              },
                            })
                          : _vm._e(),
                        field.type === "check_box"
                          ? _c("CheckBoxShow", {
                              attrs: {
                                item: field,
                                showQuestionNumber: true,
                                showQuestionScore: true,
                                index: _vm.questionNumber(index),
                              },
                            })
                          : _vm._e(),
                        field.type === "gap"
                          ? _c("GapShow", {
                              attrs: {
                                item: field,
                                showQuestionNumber: true,
                                showQuestionScore: true,
                                index: _vm.questionNumber(index),
                              },
                            })
                          : _vm._e(),
                        field.type === "essay"
                          ? _c("EssayShow", {
                              attrs: {
                                item: field,
                                showAnswerSection: false,
                                hide_score: false,
                                showQuestionNumber: true,
                                showQuestionScore: true,
                                index: _vm.questionNumber(index),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            }),
            _c("div", { staticClass: "vx-col grid w-full mt-6" }, [
              _vm.organizationLogo
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex items-stretch mx-auto place-self-center",
                    },
                    [
                      _c("div", { staticClass: "flex items-center" }, [
                        _c(
                          "label",
                          {
                            staticClass: "text-black text-sm mr-2 text-center",
                          },
                          [_vm._v(_vm._s(_vm.$t("made_by")))]
                        ),
                      ]),
                      _c("img", {
                        staticStyle: { "max-width": "100px" },
                        attrs: { src: _vm.organizationLogo },
                      }),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "flex items-stretch mx-auto place-self-center" },
                [
                  _c("label", { staticClass: "text-primary text-sm mr-2" }, [
                    _vm._v(_vm._s(_vm.$t("Powered_by"))),
                  ]),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://educatena.com.br",
                        target: "_blank",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { "max-width": "100px" },
                        attrs: {
                          src: require("@/assets/images/logo/logo.png"),
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }